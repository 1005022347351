import React from 'react'
import {
  Container,
  DownloadButton,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { PdfViewer } from "../../../components/core/pdf";
import { OfferContent } from '../../../components/site/offer'
import { PageLayout } from '../../../components/site/layout/page-layout'

const videoId = 'VcCrVdBzS8s'
const pdfUrl = '/download/OstermanResearch-StateOfMobileAppSecurity2022.pdf'
const pdfName =
  'Osterman Research - The State of Mobile App Security in 2022.pdf'

const ViewPage = ({ location }) => {
  const offer = 'serverless'

  const Headline = () => {
    return (
      <Section>
        <Container className="mb-8 container-lg">
          <h1 className="mt-12 mb-4 text-3xl text-center">
            Osterman Research Report: “The State of Mobile App Security 2022"
          </h1>
          <h3 className="mt-4 mb-2 text-xl text-center text-gray-700">
            Findings Reveal Both the Increased Importance of Mobile Apps and The
            Relative Lack of Focus on Runtime App and Data Protection
          </h3>
        </Container>
      </Section>
    )
  }

  const Intro = () => {
    return (
      <>
        <p>
          Mobile apps have become key tools for businesses to serve customers,
          earn revenue, and enable remote work by employees. Over the last two
          years, mobile apps have become critical to success for the majority of
          businesses.
        </p>
        <p>
          In this report, we present the findings of a survey into the state of
          mobile app security in 2022, encompassing survey respondents from
          across the United States and the United Kingdom. The survey and white
          paper were commissioned by Approov.
        </p>
      </>
    )
  }

  const Action = () => {
    return (
      <>
        <p>
          Approov can help your business secure its mobile APIs quickly and
          effectively. Want to learn more about how we helped dozens of
          companies ensure that only their mobile apps can use their APIs? Talk
          to us.
        </p>

        <div className="flex flex-col items-center">
          <LinkButton
            className="button-secondary button-lg"
            href="/product/consult"
          >
            Talk to an Expert
          </LinkButton>
        </div>
      </>
    )
  }

  return (
    <PageLayout pathname={location.pathname}>

      <Headline />

      <OfferContent offer={offer}>
        <Section>
          <Container className="mb-8 scroll-mt-32">
            <Intro />
          </Container>

          <Container className="pt-0 pb-4 ">
            <h2 className="mt-12 mb-4 text-3xl">Webinar Recording</h2>
            <div className="flex items-center justify-center w-full">
              <div className="w-full border border-gray-300 aspect-w-16 aspect-h-9">
                <iframe
                  title="Best Practices for Secure Access of 3rd Party APIs from Mobile Apps"
                  src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Container>

          <Container>
            <h2 className="mt-12 mb-4 text-3xl">Research Report</h2>
            <div className="flex items-center justify-center py-4">
              <DownloadButton
                className="button-secondary button-lg"
                downloadUrl={pdfUrl}
                fileName={pdfName}
              >
                Download PDF
              </DownloadButton>
            </div>
            <PdfViewer file={pdfUrl} />
          </Container>

          <Container className="mb-8">
            <Action />
          </Container>
        </Section>
      </OfferContent>
    </PageLayout>
  )
}

export default ViewPage
